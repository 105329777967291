import { environment } from './../environments/environment';
import { FullPageModule } from './pages/full/full.page.module';
import { ImportableTranslatorModule } from './modules/translator/importableTranslator.module';
import { FramePageModule } from './pages/frame/frame.page.module';
import { WizardPageModule } from './pages/wizard/wizard.page.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    WizardPageModule,
    FullPageModule,
    FramePageModule,
    ImportableTranslatorModule,
    NgbModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: 'googleTagManagerId', useValue: environment.googleTagManagerId }
  ]
})
export class AppModule { }
