import { WizardService } from '../../wizard.service';
import { Component } from '@angular/core';

@Component({
  selector: 'wizard-portability-screen',
  templateUrl: 'wizard-portability.screen.component.html',
})
export class WizardPortabilityScreenComponent {
  constructor(public wizardSer: WizardService) {}
}
