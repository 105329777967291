import { FormlyNumberFieldComponent } from './fields/number/formly-number.field.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { ChoicesField } from './fields/choices.field';
import { FormlyStringField } from './fields/formlyString.field';
import { ButtonField } from './fields/button.field';
import { ReactiveFormsModule } from '@angular/forms';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { NgModule } from '@angular/core';
import { FormlyHorizontalWrapperComponent } from './fields/horizontal-wrapper';
import { CommonModule } from '@angular/common';
import { NgxMaskModule } from 'ngx-mask';
import { NgbPopoverModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';

const FIELDS = [
  FormlyNumberFieldComponent,
  FormlyStringField,
  ButtonField,
  ChoicesField,
];

@NgModule({
  declarations: [...FIELDS],
  imports: [
    NgbModule,
    NgbPopoverModule,
    NgxMaskModule.forRoot(),
    BrowserModule,
    CommonModule,
    FormlyBootstrapModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    FormlyModule.forRoot({
      wrappers: [
        { name: 'col-wrapper', component: FormlyHorizontalWrapperComponent },
      ],
      validationMessages: [
        // {name: 'required', message: 'field is required'}, // todo translate
      ],
      types: [
        { name: 'number', component: FormlyNumberFieldComponent },
        { name: 'string', component: FormlyStringField },
        { name: 'button', component: ButtonField },
        { name: 'choices', component: ChoicesField },
      ],
    }),
  ],
  exports: [ReactiveFormsModule, FormsModule, FormlyModule],
})
export class ImportableFormModule {}
