import * as percent from 'percent';

export class MortgageCalculatorModel {
  price: number = 0;
  annualIncome: number = 0;
  ownFunds: number = 0;
  interestRate: number = 5;

  get mortgage() {
    let mortgage = this.price - this.ownFunds;
    return mortgage > 0 ? mortgage : 0;
  }

  get amortizationYearly() {
    let twoThirdsOfPrice = (this.price / 3) * 2;
    // If less than two-thirds of the purchase price is financed, no amortization costs occur
    if (this.mortgage <= twoThirdsOfPrice) return 0;

    // return ((this.price * 0.8) - (this.price * 0.66)) / 15;
    return (this.mortgage - twoThirdsOfPrice) / 15;
  }

  get amortizationMonthly() {
    return this.amortizationYearly / 12 || 0;
  }

  get interestMonthly() {
    let interest =
      ((this.price - this.ownFunds) * this.interestRate) / (100 * 12);
    return interest > 0 ? interest : 0;
  }

  get interestYearly() {
    return this.interestMonthly * 12 || 0;
  }

  get maintenanceCostsMonthly() {
    return this.price / 100 / 12;
  }

  get maintenanceCostsYearly() {
    return this.price / 100;
  }

  get monthlyCosts() {
    return (
      this.interestMonthly +
      this.amortizationMonthly +
      this.maintenanceCostsMonthly
    );
  }

  get portability() {
    let fivePercentOfMortgage = (this.mortgage / 100) * 5;
    let sum =
      fivePercentOfMortgage +
      this.amortizationYearly +
      this.maintenanceCostsYearly;

    return (sum * 100) / (this.annualIncome * 100);
  }

  get monthlyIncome() {
    return this.annualIncome / 12;
  }

  // template

  get monthlyCostsToMonthlyIncomeInPercent() {
    return percent.calc(this.monthlyCosts, this.monthlyIncome, 0);
  }

  get ownFundsPercentComparedToPrice() {
    return percent.calc(this.ownFunds, this.price, 0);
  }

  get hasSufficientOwnFunds() {
    return this.ownFundsPercentComparedToPrice >= 20;
  }

  get hasSufficientPortability() {
    return (
      this.monthlyCostsToMonthlyIncomeInPercent <= 33 && this.annualIncome > 0
    );
  }

  get hasLimitedPortability() {
    return (
      this.monthlyCostsToMonthlyIncomeInPercent < 40 &&
      this.monthlyCostsToMonthlyIncomeInPercent > 33
    );
  }

  get isGenerallyFinancable() {
    return this.hasSufficientOwnFunds && this.hasSufficientPortability;
  }

  get isGenerallyLimitedFinancable() {
    return this.hasSufficientOwnFunds && this.hasLimitedPortability;
  }

  toJson() {
    return {
      price: this.price,
      annualIncome: this.annualIncome,
      ownFunds: this.ownFunds,
      //interestRate: this.interestRate,
      //amortizationYearly: this.amortizationYearly,
      //amortizationMonthly: this.amortizationMonthly,
      //interestMonthly: this.interestMonthly,
      //interestYearly: this.interestYearly,
      //maintenanceCostsMonthly: this.maintenanceCostsMonthly,
      //maintenanceCostsYearly: this.maintenanceCostsYearly,
      //monthlyCosts: this.monthlyCosts,
      //portability: this.portability,
      //monthlyIncome: this.monthlyIncome,
      //monthlyCostsToMonthlyIncomeInPercent: this
      //.monthlyCostsToMonthlyIncomeInPercent,
      //ownFundsPercentComparedToPrice: this.ownFundsPercentComparedToPrice,
      //hasSufficientOwnFunds: this.hasSufficientOwnFunds,
      // hasSufficientPortability: this.hasSufficientPortability,
      // hasLimitedPortability: this.hasLimitedPortability,
      isGenerallyFinancable: this.isGenerallyFinancable,
      isGenerallyLimitedFinancable: this.isGenerallyLimitedFinancable,
    };
  }
}
