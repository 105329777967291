import { WizardService } from './../../wizard.service';
import { Component } from '@angular/core';

@Component({
  selector: 'wizard-home-screen',
  templateUrl: 'wizard-home.screen.component.html',
})
export class WizardHomeScreenComponent {
  constructor(public wizardSer: WizardService) {}
}
