import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-image-loader',
  templateUrl: './house-loader.component.html',
  styleUrls: ['./house-loader.component.scss'],
})
export class HouseImageLoaderComponent {
  private _percent = 0;

  @Input()
  set percent(percent: number) {
    if (percent > 100) percent = 100;
    this._percent = percent;
  }

  get percent() {
    return this._percent;
  }

  get gradient() {
    return this.positive
      ? 'url(#house-loader-gradient-success)'
      : 'url(#house-loader-gradient-danger)';
  }

  @Input()
  positive = true;
}
