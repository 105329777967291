<section class="fade-in w-100">

  <h2 class="text-center wizard-home-title lato-web-bold">
    {{ 'wizard.landingTitle' | translate }}
  </h2>

  <div class="w-100 home-listing mt-5 d-none d-sm-flex d-lg-flex">
    <div class="mx-auto">
      <div class="d-flex mx-auto">
        <h4 class="fas fa-check mr-lg-3 mr-2 text-primary"></h4>
        <h4 class=" text-nowrap">{{ 'monthlyRepayment' | translate }}</h4>
      </div>
      <div class="d-flex mx-auto">
        <h4 class="fas fa-check mr-lg-3 mr-2 text-primary"></h4>
        <h4>{{ 'investiture.title' | translate }}</h4>
      </div>
      <div class="d-flex mx-auto">
        <h4 class="fas fa-check mr-lg-3 mr-2 text-primary"></h4>
        <h4>{{ 'portability.title' | translate }}</h4>
      </div>
    </div>
  </div>


  <div class="d-flex w-100 mt-lg-2 mb-lg-4 my-1 my-md-2">
    <img class="mx-auto wizard-entry-image" src="/assets/images/wizard-home.svg">
  </div>


  <div class="col-lg-10 text-center mx-auto">
    <p class="text-home-screen" [innerHtml]="'wizard.landingText' | translate"></p>
  </div>

  <div class="d-flex w-100 mt-lg-5 mt-md-2 mt-2">
    <button class="d-flex mx-auto btn btn-primary rounded-pill btn-lg text-white text-lg px-5 py-3 mb-5"
      (click)="wizardSer.forward()">
      <strong class="text-uppercase text-nowrap small">{{ 'wizard.landingButton' | translate }}</strong>
      <span class="fas fa-chevron-right my-auto ml-3 small"></span>
    </button>
  </div>


</section>
