import { MortgageCalculatorModel } from './../../model/MortgageCalculation';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'mortgage-calculator-summary',
  template: `
    <h2 *ngIf="showTitle">3. {{ 'summary.title' | translate }}</h2>
    <hr *ngIf="showTitle" />

    <div *ngIf="model.isGenerallyFinancable" class="alert alert-success">
      <div class="row">
        <div
          class="col-lg-2 offset-2 d-none d-lg-block align-self-center text-right"
        >
          <span class="fa fa-home fa-5x"></span>
        </div>
        <div class="col-lg-8 align-self-center">
          <div>
            <strong class="text-uppercase">{{
              'summary.positive.title' | translate
            }}</strong>
          </div>
          <div>
            <small>{{ 'summary.positive.line1' | translate }}</small>
          </div>
          <div>
            <small>{{ 'summary.positive.line2' | translate }}</small>
          </div>
          <div>
            <small>{{ 'summary.positive.line3' | translate }}</small>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="model.isGenerallyLimitedFinancable" class="alert alert-warning">
      <div class="row">
        <div
          class="col-lg-2 offset-2 d-none d-lg-block align-self-center text-right"
        >
          <span class="fa fa-home fa-5x"></span>
        </div>
        <div class="col-lg-8 justify-content-center align-self-center">
          <div>
            <strong class="text-uppercase">{{
              'summary.limited.title' | translate
            }}</strong>
          </div>
          <div>
            <small>{{ 'summary.limited.line1' | translate }}</small>
          </div>
          <div>
            <small>{{ 'summary.limited.line2' | translate }}</small>
          </div>
          <div>
            <small>{{ 'summary.limited.line3' | translate }}</small>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="
        !model.isGenerallyFinancable && !model.isGenerallyLimitedFinancable
      "
      class="alert alert-danger"
    >
      <div class="row">
        <div
          class="col-lg-2 offset-2 d-none d-lg-block align-self-center text-right"
        >
          <i class="fas fa-home fa-4x"></i>
        </div>
        <div class="col-lg-8 justify-content-center align-self-center">
          <div>
            <strong class="text-uppercase">{{
              'summary.negative.title' | translate
            }}</strong>
          </div>
          <div *ngIf="!model.hasSufficientOwnFunds">
            <small>{{ 'summary.negative.line1' | translate }}</small>
          </div>
          <div
            *ngIf="
              !model.hasLimitedPortability && !model.hasSufficientPortability
            "
          >
            <small>{{ 'summary.negative.line2' | translate }}</small>
          </div>
          <div>
            <small>{{ 'summary.negative.line3' | translate }}</small>
          </div>
        </div>
      </div>
    </div>
  `,
})
export class SummaryComponent {
  @Input()
  model: MortgageCalculatorModel;

  @Input()
  showTitle: boolean = true;
}
