<section class="fade-in pt-5">
  <div class="mx-auto text-center">
    <h2>{{ 'summary.title' | translate }}</h2>
  </div>

  <div class="d-flex w-100 my-5">
    <img class="wizard-summary-image mx-auto" src="{{ imageSrc }}">
  </div>

  <div class="container-fluid bg-light mt-5 p-3 d-flex">
    <div class="mx-auto d-flex text-{{ accent }}">
      <i class="fas fa-{{ faLogo }} fa-2x mr-2 fa-2x my-auto p-2"></i>
      <strong class="my-auto">{{ title | translate }}</strong>
    </div>
  </div>

  <div class="d-flex w-100">
    <div class="w-75 mx-auto pt-3">
      <p class="lato-web-bold">{{ leadTitle | translate }}?</p>
      <p>{{ leadText | translate }}</p>
      <p class="lato-web-bold">{{ 'summary.lead' | translate  }}</p>
    </div>
  </div>


  <div class="row">
    <div class="col my-5">
      <wizard-nav-buttons></wizard-nav-buttons>
    </div>
    <div class="col mt-2">
      <img class="img img-fluid" src="/assets/images/arrow.png">
    </div>
  </div>


</section>
