<section class="fade-in pt-5">
  <div class="mx-auto text-center">
    <h2>{{ wizardSer.page }}. {{ 'portability.title' | translate }}</h2>
    <strong class="lato-web-light">{{ 'portability.question' | translate }}</strong>
  </div>

  <div class="m-5">
    <mortgage-calculator-portability [showHeader]="false" [model]="wizardSer.model">
    </mortgage-calculator-portability>
  </div>

  <div class="mb-5">
    <wizard-nav-buttons></wizard-nav-buttons>
  </div>

  <div class="container-fluid m-0 p-0 bg-light p-5 text-center">
    {{ 'portability.text' | translate }}
  </div>

</section>
