import { BrowserModule } from '@angular/platform-browser';
import { FullCalculatorComponent } from './components/full-calculator/full-calculator.component';
import { RingLoaderModule } from './../ring-loader/ring-loader.module';
import { IconAlertModule } from './../icon-alert/icon-alert.module';
import { MiscModule } from './../misc/misc.module';
import { HouseLoaderModule } from './../house-loader/house-loader.module';
import { PortabilityDropdownComponent } from './components/portability-dropdown/portability-dropdown.component';
import { PortabilityComponent } from './components/portability/portability.component';
import { InvestitureComponent } from './components/investiture/investiture.component';
import { ImportableTranslatorModule } from './../translator/importableTranslator.module';
import { SummaryComponent } from './components/summary/summary.component';
import { FrameFormComponent } from './components/frame-form/frame-form.component';
import { NgModule } from '@angular/core';
import { ImportableFormModule } from '../form/form.module';
import { CommonModule } from '@angular/common';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

const COMPONENTS = [
  FrameFormComponent,
  SummaryComponent,
  InvestitureComponent,
  PortabilityComponent,
  PortabilityDropdownComponent,
  FullCalculatorComponent,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    IconAlertModule,
    RingLoaderModule,
    ImportableTranslatorModule,
    ImportableFormModule,
    HouseLoaderModule,
    MiscModule,
    ScrollToModule.forRoot(),
  ],
  exports: [...COMPONENTS],
})
export class CalculatorModule {}
