import { WizardService } from '../../wizard.service';
import { Component } from '@angular/core';

@Component({
  selector: 'wizard-nav-buttons',
  templateUrl: 'nav-buttons.component.html',
})
export class NavButtonsComponent {
  constructor(public wizardSer: WizardService) {}
}
