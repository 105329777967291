<div class="alert alert-info cursor-pointer portability-dropdown-box-hover" (click)="changeState()" [ngClass]="{'portability-dropdown-box-open-state':state === 'open'}
">
  <div class="row d-flex h-100">
    <div class="my-auto mx-auto">
      <span class="fa fa-info-circle fa-3x"></span>
    </div>
    <div class="col-7 justify-content-center align-self-center">
      {{ 'portability.alertLeft' | translate }} <span
        class='ml-auto'>{{ model.monthlyCosts | amountConverter | currencyConverter }}</span>
    </div>
    <div class="col-1 justify-content-center align-self-center">
      <span class="justify-content-center fa fa-{{ state === 'close' ? 'chevron-down' : 'chevron-up' }}"></span>
    </div>
  </div>
  <div *ngIf="state === 'open'" class="fade-in fade-out">
    <hr>
    <div class="row">
      <div class="container">
        <div class="row">
          <div class="col-7 offset-1">
            <p>{{ 'interests' | translate }}</p>
          </div>
          <div class="col-4">
            <p class="portability-dropdown-numbers">{{ model.interestMonthly | amountConverter |
                            currencyConverter }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-1">
            <span class="fa fa-plus portability-dropdown-fa"></span>
          </div>
          <div class="col-7">
            <p>{{ 'amortization' | translate }}</p>
          </div>
          <div class="col-4">
            <p class="portability-dropdown-numbers">{{ model.amortizationMonthly | amountConverter |
                            currencyConverter }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-1">
            <span class="fa fa-plus portability-dropdown-fa"></span>
          </div>
          <div class="col-7">
            <p>{{ 'maintenanceCosts' | translate }}</p>
          </div>
          <div class="col-4">
            <p class="portability-dropdown-numbers">{{ model.maintenanceCostsMonthly | amountConverter |
                            currencyConverter }}</p>
          </div>
        </div>
        <hr>
        <div class="row">
          <div class="col-7 offset-1">
            <p class='font-weight-bold'>{{ 'monthlyPaymentsTotal' | translate }}</p>
          </div>
          <div class="col-4">
            <p class="portability-dropdown-numbers">
              {{ model.monthlyCosts | amountConverter | currencyConverter }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
