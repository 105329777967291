import { Component, Input } from '@angular/core';
import { MortgageCalculatorModel } from './../../model/MortgageCalculation';

@Component({
  selector: 'app-dropdown-portability',
  styleUrls: ['./portability-dropdown.component.scss'],
  templateUrl: './portability-dropdown.component.html',
})
export class PortabilityDropdownComponent {
  @Input()
  model: MortgageCalculatorModel;

  state: 'open' | 'close' = 'close';

  changeState() {
    this.state = this.state === 'open' ? 'close' : 'open';
  }
}
