<ng-container *ngIf="showHeader">
  <h2>1. {{ 'investiture.title' | translate }}</h2>
  <hr />
  <div class="margin-bottom-3">
    <small>{{ 'investiture.text' | translate }}</small>
  </div>
</ng-container>

<div class="row margin-bottom-2">
  <div class="col-lg-6">
    <form [formGroup]="form" (change)="modelChange()">
      <formly-form [form]="form" [fields]="fields" [model]="model">
      </formly-form>
    </form>
  </div>
  <div class="col-lg-6">
    <app-image-loader [positive]="model.hasSufficientOwnFunds" [percent]="model.ownFundsPercentComparedToPrice">
    </app-image-loader>
  </div>
</div>

<div class="row">
  <div class="col-lg-6">
    <app-icon-alert icon="info-circle" type="info" text="{{ 'investiture.alertLeft' | translate }}: {{
        model.mortgage | amountConverter | currencyConverter
      }}"></app-icon-alert>
  </div>
  <div class="col-lg-6">
    <app-icon-alert icon="check-circle" *ngIf="model.hasSufficientOwnFunds; else invalid" type="success"
      text="investiture.alertRightPositive"></app-icon-alert>
    <ng-template #invalid>
      <app-icon-alert icon="exclamation-circle" type="{{ !model.price && !model.ownFunds ? 'info' : 'danger' }}"
        text="investiture.alertRightNegative"></app-icon-alert>
    </ng-template>
  </div>
</div>
