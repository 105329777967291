<div class="d-none d-lg-flex justify-content-center">
    <svg
            id="haus"
            xmlns="http://www.w3.org/2000/svg"
            width="600"
            height="270"
            viewBox="0 0 600 270"
    >
        <defs>
            <!--success gradient-->
            <linearGradient id="house-loader-gradient-success" x1="0%" y1="0%" x2="0%" y2="104%">
                <stop [attr.offset]="100 - percent + '%'" stop-opacity="1" stop-color="#fafafa"/>
                <stop [attr.offset]="100 - percent + '%'" stop-opacity="1" stop-color="#98b220"/>
                <stop [attr.offset]="200 - percent + '%'" stop-opacity="1" stop-color="#739c11"/>
                <stop [attr.offset]="100 - percent + '%'" stop-opacity="1" stop-color="#98b220"/>
            </linearGradient>
            <!--danger gradient-->
            <linearGradient id="house-loader-gradient-danger" x1="0%" y1="0%" x2="0%" y2="104%">
                <stop [attr.offset]="100 - percent + '%'" stop-opacity="1" stop-color="#fafafa"/>
                <stop [attr.offset]="100 - percent + '%'" stop-opacity="1" stop-color="#d53d1a"/>
                <stop [attr.offset]="200 - percent + '%'" stop-opacity="1" stop-color="#ec6131"/>
                <stop [attr.offset]="percent + '%'" stop-opacity="1" stop-color="#ec6131"/>
            </linearGradient>
        </defs>
        <!--house-->
        <path
                class="house-loader-house-filling"
                [attr.fill]="gradient"
                stroke="#d8d4d3"
                stroke-width="4.37px"
                fill-rule="nonzero"
                d="M152.268,120.083S300.456,1.5,300.467,1.5,448.433,120.083,448.433,120.083s-14.94,17.532-14.937,17.531-22.669-17.5-22.663-17.446V266.514H189.353s0.01-146.517,0-146.519-22.661,17.619-22.663,17.619S152.268,120.083,152.268,120.083Z"
        />
        <!-- mortgage -->
        <!--<line x1="450" y1="80" x2="300" y2="80" stroke="#c1c1c1" stroke-dasharray="4"/>-->
        <text x="455" y="60" fill="#c1c1c1" style="font-size: 0.8em">{{ 'mortgage' | translate }}</text>
        <text x="455" y="90" fill="#c1c1c1" style="font-size: 2em">{{ 100 - percent }}%</text>
        <!-- own funds -->
        <!--<line x1="450" y1="180" x2="300" y2="180" stroke="#c1c1c1" stroke-dasharray="4"/>-->
        <text x="455" y="160" fill="#c1c1c1" style="font-size: 0.8em">{{ 'ownFunds' | translate }}</text>
        <text x="455" y="190" fill="#c1c1c1" style="font-size: 2em">{{ percent }}%</text>
        <!-- min funds -->
        <line x1="410" y1="223" x2="150" y2="223" stroke="#c1c1c1" stroke-dasharray="4"/>
        <text x="20" y="228" fill="#c1c1c1" style="font-size: 0.8em">{{ 'minOwnFunds' | translate }}</text>
    </svg>
</div>