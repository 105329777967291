import { CurrencyPipe } from './pipes/currency.pipe';
import { AmountConverterPipe } from './pipes/amountConverter.pipe';
import { PostMessageService } from './services/postMessage.service';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [AmountConverterPipe, CurrencyPipe],
  providers: [PostMessageService],
  exports: [AmountConverterPipe, CurrencyPipe],
})
export class MiscModule {}
