import { PostMessageService } from './modules/misc/services/postMessage.service';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as $ from 'jquery';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    private router: Router,
    private transSer: TranslateService,
    private postMessageService: PostMessageService,
    private route: ActivatedRoute,
    private googleTagManager: GoogleTagManagerService,
  ) { }

  ngOnInit() {
    this.checkScroll();
    this.initResize();
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        const lang = this.router.parseUrl(e.urlAfterRedirects).root?.children
          ?.primary?.segments[0]?.path;

        this.transSer.use(lang || 'de');

        const gtmTag = {
          event: 'page',
          pageName: e.url
        };
        this.googleTagManager.pushTag(gtmTag);
      }
    });
  }

  checkScroll() {
    this.route.queryParams.subscribe((qp) => {
      if (qp.disableScroll) $('body, html').css('overflow', 'hidden');
    });
  }

  initResize() {
    setInterval((d) => {
      this.resize();
    }, 100);
  }

  get contentHeight() {
    let d = document.getElementById('wizard') || document.body;
    let h = Math.max(
      d.scrollHeight,
      d.scrollHeight,
      d.offsetHeight,
      d.offsetHeight,
      d.clientHeight,
      d.clientHeight
    );
    return h;
  }

  get contentWidth() {
    let d = document.getElementById('wizard') || document.body;
    let w = Math.max(
      d.scrollWidth,
      d.scrollWidth,
      d.offsetWidth,
      d.offsetWidth,
      d.clientWidth,
      d.clientWidth
    );
    return w;
  }

  resize() {
    this.postMessageService.fireEvent('resize', {
      height: this.contentHeight,
      width: this.contentWidth,
    });
  }
}
