<ng-container *ngIf="showHeader">
  <h2>2. {{ 'portability.title' | translate }}</h2>
  <hr />
  <div class="margin-bottom-3">
    <small>{{ 'portability.text' | translate }}</small>
  </div>
</ng-container>
<div class="row margin-bottom-2">
  <div class="col-lg-6">
    <form [formGroup]="form" (change)="modelChange()">
      <formly-form [form]="form" [fields]="fields" [model]="model">
      </formly-form>
    </form>
  </div>
  <div class="col-lg-6">
    <app-ring-loader [percent]="100 - model.monthlyCostsToMonthlyIncomeInPercent" [colorSchema]="ringColor">
    </app-ring-loader>
  </div>
</div>

<div class="row">
  <div class="col-lg-6">
    <app-dropdown-portability [model]="model"></app-dropdown-portability>
  </div>
  <div class="col-lg-6">
    <app-icon-alert *ngIf="model.hasSufficientPortability" icon="check-circle" type="success"
      text="{{ 'portability.alertRightPositive' }}"></app-icon-alert>
    <app-icon-alert *ngIf="model.hasLimitedPortability" icon="exclamation-circle" type="warning"
      text="{{ 'portability.alertRightLimited' }}"></app-icon-alert>
    <app-icon-alert *ngIf="
        !model.hasSufficientPortability && !model.hasLimitedPortability
      " icon="exclamation-circle" type="danger" text="{{ 'portability.alertRightNegative' }}"></app-icon-alert>
  </div>
</div>
