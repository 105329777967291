import {Component, ViewChild} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
    selector: 'formly-field-choices',
    template: `
        <div class="number-field-fieldset" (click)="focus()">
            <fieldset class="border p-2">
                <legend class="w-auto">{{ field.templateOptions?.label | translate }}</legend>
                <select
                        class="form-control"
                        #nativeField
                        [(ngModel)]="val"
                        (change)="valChange()"
                >
                    <option class="form-control" *ngFor="let o of options" [value]="o.value" selected>{{ o.name | translate }}</option>
                </select>
            </fieldset>
        </div>
    `,
})
export class ChoicesField extends FieldType {

    @ViewChild('nativeField', { read: true })
    nativeField: any;
    val;
    options: any = [];

    ngOnInit() {
        this.options = this.field.templateOptions.options;
        this.val = this.formControl.value;
    }

    valChange() {
        this.formControl.setValue(this.val);
    }

    focus() {
        this.nativeField.nativeElement.focus();
    }
}
