import {Component, OnInit, ViewChild} from '@angular/core';
import {FieldType} from '@ngx-formly/core';

@Component({
    selector: 'app-formly-string-field',
    template: `        
        <div class="number-field-fieldset" (click)="focus()">
            <fieldset class="border p-2">
                <legend class="w-auto">{{ field.templateOptions?.label | translate }}</legend>
                <input
                        class="form-control"
                        #nativeField
                        type="text"
                        [(ngModel)]="val"
                        (change)="valChange()"
                >
            </fieldset>
        </div>
    `
})
export class FormlyStringField extends FieldType implements OnInit {

    @ViewChild('nativeField', { read: true })
    nativeField: any;
    val;

    ngOnInit() {
        this.val = this.formControl.value;
    }

    valChange() {
        this.formControl.setValue(this.val);
    }

    focus() {
        this.nativeField.nativeElement.focus();
    }
}
