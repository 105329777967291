import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-button',
  template: `
    <div style="margin-top: 3%">
      <button
        type="submit"
        class="btn btn-primary btn-block btn-lg text-uppercase"
        (click)="onClick($event)"
      >
        {{ 'contact.submit' | translate }}
      </button>
    </div>
  `,
})
export class ButtonField extends FieldType {
  onClick($event) {
    if (this.to.onClick) {
      this.to.onClick($event);
    }
  }
}
