import { Component, OnInit, ViewChild } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-number-field',
  templateUrl: 'formly-number.field.component.html',
  styleUrls: ['formly-number.field.component.scss'],
})
export class FormlyNumberFieldComponent extends FieldType implements OnInit {
  @ViewChild('inputNumberField', { read: true })
  numberField: any;

  sliderVal: number;
  val: any; // any because of the mask feature
  mask: string = '0*';

  prepend: any = null;
  append: any = null;
  tooltip: string = '';
  isReadOnly: boolean = false;

  theme: 'default' | 'wizard' | 'full' = 'default';

  ngOnInit() {
    this.theme = this.field.templateOptions.theme || 'default';
    this.append = this.field.templateOptions.append;
    this.prepend = this.field.templateOptions.prepend;
    this.tooltip = this.field.templateOptions.tooltip;
    this.isReadOnly = this.field.templateOptions.readonly;

    this.val = this.field.formControl.value;
    if (!this.val) this.val = 0;
    this.sliderVal = this.val;

    this.updateMask();
  }

  focus() {
    this.numberField.nativeElement.focus();
  }

  sliderMove() {
    this.val = this.sliderVal;
    this.update();
    this.updateMask();
  }

  valChange() {
    this.sliderVal = this.val;
    this.updateMask();
    this.update();
  }

  update() {
    if (!this.val) this.val = 0;
    let val = Number.isInteger(this.val)
      ? parseInt(this.val)
      : parseFloat(this.val);
    this.field.formControl.setValue(val);
    this.updateMask();
  }

  removeMask() {
    this.mask = this.field.templateOptions.step < 1 ? '0.0' : '000000000';
  }

  updateMask() {
    let pattern: string = '000000000';
    if (this.val >= 10000 && this.val < 100000) pattern = `00 000`;
    if (this.val >= 100000 && this.val < 1000000) pattern = '000 000';
    if (this.val >= 1000000) pattern = '0 000 000';
    if (this.field.templateOptions.step < 1) pattern = '0.0';

    this.mask = pattern;
  }
}
