import { NavButtonsComponent } from './misc/nav-buttons/nav-buttons.component';
import { WizardSummaryScreenComponent } from './screens/summary/wizard-summary.screen.component';
import { WizardPortabilityScreenComponent } from './screens/portability/wizard-portability.screen.component';
import { WizardService } from './wizard.service';
import { WizardInvestitureScreenComponent } from './screens/investiture/wizard-investiture.screen.component';
import { WizardHomeScreenComponent } from './screens/home/wizard-home.screen.component';
import { NgbPopoverModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ImportableTranslatorModule } from './../../modules/translator/importableTranslator.module';
import { CommonModule } from '@angular/common';
import { CalculatorModule } from './../../modules/calculator/calculator.module';
import { WizardPageComponent } from './wizard.page.component';
import { NgModule } from '@angular/core';

const SCREENS = [
  WizardHomeScreenComponent,
  WizardInvestitureScreenComponent,
  WizardPortabilityScreenComponent,
  WizardSummaryScreenComponent,
  NavButtonsComponent,
];

@NgModule({
  imports: [
    CalculatorModule,
    CommonModule,
    ImportableTranslatorModule,
    NgbModule,
    NgbPopoverModule,
  ],
  providers: [WizardService],
  declarations: [WizardPageComponent, ...SCREENS],
  exports: [WizardPageComponent],
})
export class WizardPageModule {}
