import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'currencyConverter'
})
export class CurrencyPipe implements PipeTransform {

    transform(value: number): string {
        return `${value}.- CHF`;
    }

}