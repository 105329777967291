import { ImportableTranslatorModule } from './../translator/importableTranslator.module';
import { HouseImageLoaderComponent } from './../house-loader/house-loader.component';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [HouseImageLoaderComponent],
  imports: [ImportableTranslatorModule],
  exports: [HouseImageLoaderComponent],
})
export class HouseLoaderModule {}
