<section id="wizard">

  <section class="wizard-nav d-flex" [ngClass]="{
    'wizard-nav-height-home': (wizardSer.page === 0 || wizardSer.isOnLastPage),
    'wizard-nav-height-step': wizardSer.page > 0
  }">

    <div class="container wizard-nav-inner align-content-center" [ngClass]="{
      'wizard-nav-inner-height-home': (wizardSer.page === 0 || wizardSer.isOnLastPage),
      'wizard-nav-inner-height-step': wizardSer.page > 0
    }">

      <p class="text-center h4 d-flex w-100" [ngClass]="{'h-100':wizardSer.page === 0}">
        <strong
          class="text-white text-uppercase mx-auto my-auto lato-web-light">{{ 'mortgageCalculator.title' | translate }}</strong>
      </p>

      <div class="w-75 mx-auto d-flex justify-content-center mt-2 mt-lg-3" *ngIf="wizardSer.page !== 0">

        <div clas="flex-fill">
          <div class="bullet-point text-center" [class.bullet-point-disabled]="wizardSer.page === 0">
            <strong class="text-primary lato-web-light">1</strong>
          </div>
        </div>

        <div class="flex-fill d-flex">
          <hr class="mx-auto w-75 my-auto bg-white border-white nav-line" />
        </div>

        <div clas="flex-fill">
          <div class="bullet-point text-center" [class.bullet-point-disabled]="wizardSer.page <= 1">
            <strong class="text-primary lato-web-light">2</strong>
          </div>
        </div>

        <div class="flex-fill d-flex">
          <hr class="mx-auto w-75 my-auto bg-white border-white nav-line" />
        </div>

        <div clas="flex-fill">
          <div class="bullet-point text-center" [class.bullet-point-disabled]="wizardSer.page <= 2">
            <strong class="text-primary lato-web-light">3</strong>
          </div>
        </div>

      </div>


    </div>
  </section>


  <div class="container p-0 border-black shadow-lg rounded wizard-window bg-white">

    <ng-container [ngSwitch]="wizardSer.page">

      <ng-container *ngSwitchCase="0">
        <wizard-home-screen></wizard-home-screen>
      </ng-container>

      <ng-container *ngSwitchCase="1">
        <wizard-investiture-screen></wizard-investiture-screen>
      </ng-container>

      <ng-container *ngSwitchCase="2">
        <wizard-portability-screen></wizard-portability-screen>
      </ng-container>

      <ng-container *ngSwitchCase="3">
        <wizard-summary-screen></wizard-summary-screen>
      </ng-container>

    </ng-container>

  </div>

</section>
