import { PostMessageService } from './../../../misc/services/postMessage.service';
import {
  state,
  style,
  trigger,
  transition,
  animate,
} from '@angular/animations';
import { Input } from '@angular/core';
import { MortgageCalculatorModel } from './../../model/MortgageCalculation';
import { Component } from '@angular/core';
import { ScrollToService } from '@nicky-lenaers/ngx-scroll-to';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'mortgage-calculator-full',
  templateUrl: './full-calculator.component.html',
  animations: [
    trigger('inOut', [
      state(
        'in',
        style({
          opacity: 1,
          display: 'block',
        })
      ),
      state(
        'out',
        style({
          marginTop: '-50%',
          display: 'none',
          opacity: 0,
        })
      ),
      transition('in <=> out', [animate('0.5s')]),
    ]),
  ],
})
export class FullCalculatorComponent {
  model: MortgageCalculatorModel = new MortgageCalculatorModel();
  @Input()
  scrollAnimation = true;

  portabilityState: string = 'out';
  summaryState: string = 'out';

  constructor(
    private scroll: ScrollToService,
    private deviceService: DeviceDetectorService,
    private postMessageSer: PostMessageService
  ) {}

  get needToDisplayItemsDueToDevice() {
    return (
      this.deviceService.isMobile() &&
      this.deviceService.os.toLowerCase() === 'ios'
    );
  }

  onChanges() {
    this.adjustState();
    this.postMessageSer.fireEvent('modelChanges', this.model);
  }

  private adjustState() {
    this.portabilityState =
      this.model.price && this.model.ownFunds ? 'in' : 'out';
    this.summaryState =
      this.model.annualIncome && this.model.interestRate ? 'in' : 'out';
  }

  scrollTo() {
    let scrollTo = null;
    if (this.model.price && this.model.ownFunds) scrollTo = 'portability';
    if (this.model.annualIncome && this.model.interestRate)
      scrollTo = 'summary';
    if (scrollTo && this.scrollAnimation)
      this.scroll.scrollTo({ target: `#${scrollTo}` });
  }
}
