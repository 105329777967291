import { CalculatorModule } from './../../modules/calculator/calculator.module';
import { NgModule } from '@angular/core';
import { FullPageComponent } from './full.page.component';

@NgModule({
  imports: [CalculatorModule],
  declarations: [FullPageComponent],
  exports: [FullPageComponent],
})
export class FullPageModule {}
