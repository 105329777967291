import { MortgageCalculatorModel } from './../../modules/calculator/model/MortgageCalculation';
import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable()
export class WizardService {
  @Output()
  pageChange: EventEmitter<number> = new EventEmitter();

  model: MortgageCalculatorModel = new MortgageCalculatorModel();
  _page = 0;

  get page() {
    return this._page;
  }

  set page(page: number) {
    const executeEvent = this._page !== page;
    this._page = page;
    if (executeEvent) this.pageChange.emit(this._page);
  }

  back() {
    this.page = this.page > 0 ? this.page - 1 : this.page;
  }

  forward() {
    this.page = this.page < 3 ? this.page + 1 : this.page;
  }

  goTo(page: number) {
    this.page = page;
  }

  get isOnLastPage() {
    return this.page === 3;
  }

  get canProceed() {
    if (this.page === 3) return false;
    if (this.page === 1 && (!this.model.price || !this.model.ownFunds))
      return false;
    if (this.page === 2 && !this.model.monthlyIncome) return false;
    return true;
  }
}
