import { WizardService } from '../../wizard.service';
import { Component } from '@angular/core';

@Component({
  selector: 'wizard-investiture-screen',
  templateUrl: 'wizard-investiture.screen.component.html',
})
export class WizardInvestitureScreenComponent {
  constructor(public wizardSer: WizardService) {}
}
