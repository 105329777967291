<h1 class="margin-bottom-1 text-uppercase">{{ 'mortgageCalculator.title' | translate }}</h1>

<div class="margin-bottom-2">
  <small>{{ 'mortgageCalculator.text' | translate }}</small>
</div>


<div class="form-wrapper mb-5" [@inOut]="'in'">
  <mortgage-calculator-investiture [model]="model" (onChange)="onChanges()"></mortgage-calculator-investiture>
</div>

<div id="portability">
  <div class="form-wrapper mb-5"
    [@inOut]="(model.price && model.ownFunds) || needToDisplayItemsDueToDevice ? 'in' : 'out'"
    (@inOut.done)="scrollTo()">
    <mortgage-calculator-portability [model]="model" (onChange)="onChanges()">
    </mortgage-calculator-portability>
  </div>
</div>

<div id="summary">
  <div class="form-wrapper"
    [@inOut]="(model.annualIncome && model.interestRate) || needToDisplayItemsDueToDevice  ? 'in' : 'out'"
    (@inOut.done)="scrollTo()">
    <mortgage-calculator-summary [model]="model" (onChange)="onChanges()">
    </mortgage-calculator-summary>
    <small class="mt-2">
      <em>{{ 'disclaimer' | translate }}<sup>1</sup></em>
    </small>
  </div>
</div>
