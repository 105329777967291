import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { MortgageCalculatorModel } from '../../model/MortgageCalculation';

@Component({
  selector: 'mortgage-calculator-investiture',
  templateUrl: 'investiture.component.html',
})
export class InvestitureComponent {
  animate: string = 'init';
  state: boolean;

  @Output()
  onChange = new EventEmitter<MortgageCalculatorModel>();
  @Input()
  model: MortgageCalculatorModel;
  @Input()
  showHeader = true;

  form = new FormGroup({});
  fields: FormlyFieldConfig[] = [];

  ngOnInit() {
    if (!this.model) throw new Error('Mortgage Calculator Model missing!');
    this.fields = [
      {
        key: 'price',
        type: 'number',
        templateOptions: {
          min: 100000,
          max: 3000000,
          step: 20000,
          prepend: 'CHF',
          append: '.-',
          label: 'price',
          theme: 'full',
          tooltip: 'tooltip.price',
        },
      },
      {
        key: 'ownFunds',
        type: 'number',
        templateOptions: {
          min: 1000,
          max: 1000000,
          step: 1000,
          prepend: 'CHF',
          append: '.-',
          label: 'ownFunds',
          theme: 'full',
          tooltip: 'tooltip.ownFunds',
        },
      },
    ];
  }

  modelChange() {
    this.onChange.emit(this.model);
  }
}
