import { FullPageComponent } from './pages/full/full.page.component';
import { FramePageComponent } from './pages/frame/frame.page.component';
import { WizardPageComponent } from './pages/wizard/wizard.page.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: ':lang/full', component: FullPageComponent },
  { path: ':lang/wizard', component: WizardPageComponent },
  { path: ':lang/frame', component: FramePageComponent },
  { path: '', redirectTo: '/de/full', pathMatch: 'full' },
  { path: '**', redirectTo: '/de/full', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
