import { ActivatedRoute } from '@angular/router';
import { PostMessageService } from './../../../misc/services/postMessage.service';
import { MortgageCalculatorModel } from './../../model/MortgageCalculation';
import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import * as $ from 'jquery';

@Component({
  selector: 'mortgage-calculator-frame-form',
  template: `
    <form [formGroup]="form" (change)="modelChange()">
      <formly-form [form]="form" [fields]="fields" [model]="model">
        <button
          type="submit"
          class="btn btn-primary btn-block text-uppercase"
          (click)="submit()"
        >
          {{ 'contact.submit' | translate }}
        </button>
      </formly-form>
    </form>
    <div class="margin-top-2"></div>
    <mortgage-calculator-summary
      *ngIf="isVisible"
      [model]="model"
      [showTitle]="false"
    ></mortgage-calculator-summary>
  `,
})
export class FrameFormComponent {
  @Input()
  model: MortgageCalculatorModel = new MortgageCalculatorModel();
  percent: number;

  form = new FormGroup({});
  fields: FormlyFieldConfig[] = [];

  constructor(
    private postMessageSer: PostMessageService,
    private route: ActivatedRoute
  ) {}

  get isVisible() {
    return (
      this.model.ownFunds ||
      this.model.price ||
      this.model.monthlyIncome ||
      this.model.interestRate
    );
  }

  ngOnInit() {
    this.initPrefilled();
    if (!this.model) throw new Error('Mortgage Calculator Model missing!');
    this.percent = this.model.ownFundsPercentComparedToPrice;
    this.fields = [
      {
        key: 'price',
        type: 'number',
        className: 'col-lg-6',
        templateOptions: {
          min: 100000,
          max: 3000000,
          step: 20000,
          prepend: 'CHF',
          append: '.-',
          label: 'price',
        },
      },
      {
        key: 'ownFunds',
        type: 'number',
        className: 'col-lg-6',
        templateOptions: {
          min: 1000,
          max: 1000000,
          step: 1000,
          prepend: 'CHF',
          append: '.-',
          label: 'ownFunds',
        },
      },
      {
        key: 'annualIncome',
        type: 'number',
        className: 'col-lg-6',
        templateOptions: {
          min: 10000,
          max: 1000000,
          step: 1000,
          prepend: 'CHF',
          append: '.-',
          label: 'annualIncome',
        },
      },
      {
        key: 'interestRate',
        type: 'number',
        className: 'col-lg-6',
        templateOptions: {
          min: 0.1,
          max: 6,
          step: 0.1,
          append: '%',
          label: 'interestRate',
        },
      },
    ];
  }

  modelChange() {
    this.percent = this.model.ownFundsPercentComparedToPrice;
    this.postMessageSer.fireEvent('modelChanges', this.model);
  }

  submit() {
    this.postMessageSer.fireEvent('modelSubmit', this.model);
  }

  initPrefilled() {
    let params = this.route.snapshot.queryParams;
    if (params.overflow && params.overflow === 'true') {
      // prevent black bar in some FF browsers (Iframe immosky HP)
      $('html').css('overflow', 'hidden').css('-moz-overflow', 'hidden');
      $('body').css('overflow', 'hidden').css('-moz-overflow', 'hidden');
    }
    if (params.price) this.model.price = parseInt(params.price);
    if (params.ownFunds) this.model.ownFunds = parseInt(params.ownFunds);
    if (params.annualIncome)
      this.model.annualIncome = parseInt(params.annualIncome);
  }
}
