import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { MortgageCalculatorModel } from '../../model/MortgageCalculation';

@Component({
  selector: 'mortgage-calculator-portability',
  templateUrl: 'portability.component.html',
})
export class PortabilityComponent {
  state: boolean;

  @Output()
  onChange = new EventEmitter<MortgageCalculatorModel>();

  @Input()
  model: MortgageCalculatorModel;

  @Input()
  showHeader = true;

  form = new FormGroup({});
  fields: FormlyFieldConfig[] = [];

  ngOnInit() {
    this.state = this.model.hasSufficientPortability;
    if (!this.model) throw new Error('Mortgage Calculator Model missing!');
    this.fields = [
      {
        key: 'annualIncome',
        type: 'number',
        templateOptions: {
          min: 10000,
          max: 1000000,
          step: 1000,
          prepend: 'CHF',
          append: '.-',
          label: 'annualIncome',
          theme: 'full',
          tooltip: 'tooltip.annualIncome',
        },
      },
      {
        key: 'interestRate',
        type: 'number',
        templateOptions: {
          min: 0.1,
          max: 6,
          step: 0.1,
          prepend: '%',
          label: 'interestRate',
          theme: 'full',
          tooltip: 'tooltip.interestRate',
          readonly: true,
        },
      },
    ];
  }

  modelChange() {
    this.onChange.emit(this.model);
  }

  get ringColor() {
    if (this.model.hasSufficientPortability) return 'success';
    if (this.model.hasLimitedPortability) return 'warning';
    return 'danger';
  }
}
