import { PostMessageService } from './../../modules/misc/services/postMessage.service';
import { WizardService } from './wizard.service';
import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './wizard.page.component.html',
})
export class WizardPageComponent {
  constructor(
    public wizardSer: WizardService,
    private router: Router,
    private route: ActivatedRoute,
    private postMessageSer: PostMessageService
  ) {}

  ngOnInit() {
    if (this.route.snapshot.queryParams.page)
      this.wizardSer.goTo(Number(this.route.snapshot.queryParams.page));
    this.wizardSer.pageChange.subscribe((page) => {
      this.postMessageSer.fireEvent('stepChange', {
        page,
        isOnLastPage: this.wizardSer.isOnLastPage,
        model: this.wizardSer.model.toJson(),
      });
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: { page },
        queryParamsHandling: 'merge',
      });
    });
  }
}
