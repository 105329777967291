import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PostMessageService {
  constructor() {}

  public fireEvent(
    eventName: 'modelChanges' | 'modelSubmit' | 'resize' | 'stepChange',
    data
  ) {
    window.top.postMessage({ eventType: eventName, data }, '*');
  }
}
