<div class="d-flex">

  <div
    class="webkit-none mr-auto ml-5 btn btn-outline-primary rounded-pill text-primary px-lg-4 py-lg-3 fade-in hover-text-white"
    (click)="wizardSer.back()">
    <span class="fas fa-chevron-left my-auto mr-lg-3 mr-1"></span>
    <strong>{{ 'wizard.back' | translate }}</strong>
  </div>

  <button [disabled]="!wizardSer.canProceed" *ngIf="!wizardSer.isOnLastPage"
    class="webkit-none ml-auto mr-5 btn btn-primary rounded-pill text-white px-lg-4 py-lg-3 fade-in"
    (click)="wizardSer.forward()">
    <strong>{{ 'wizard.forward' | translate }}</strong>
    <span class="fas fa-chevron-right my-auto ml-lg-3 ml-1"></span>
  </button>

</div>
