<ng-container [ngSwitch]="theme">

  <ng-container *ngSwitchCase="'default'">

    <label>{{ field.templateOptions.label | translate }}</label>
    <input *ngIf="!append && !prepend" class="form-control" type="text" [(ngModel)]="val" mask="0*.00"
      (change)="valChange()" />
    <div class="input-group mb-3" *ngIf="append || prepend">
      <div class="input-group-prepend" *ngIf="prepend">
        <span class="input-group-text">{{ prepend }}</span>
      </div>
      <input class="form-control" type="text" [(ngModel)]="val" [mask]="mask" (change)="valChange(val)"
        [readonly]="isReadOnly" />
      <div class="input-group-append" *ngIf="append">
        <span class="input-group-text">
          {{ append }}
        </span>
      </div>
      <input class="slider" type="range" min="{{ field.templateOptions.min }}" max="{{ field.templateOptions.max }}"
        step="{{ field.templateOptions.step }}" [(ngModel)]="sliderVal" (input)="sliderMove()" (change)="sliderMove()"
        *ngIf="!isReadOnly" />
    </div>

  </ng-container>
  <ng-container *ngSwitchCase="'full'">

    <div class="number-field-fieldset">
      <fieldset class="border">
        <legend class="w-auto title">
          {{ field.templateOptions.label | translate }}
        </legend>
        <div class="row pl-4 mt-6">
          <div class="col-lg-1 col-2">
            <label class="my-auto currency">{{ prepend }}</label>
          </div>
          <div class="col-lg-10 col-8">
            <input type="text" [(ngModel)]="val" [mask]="mask" (change)="valChange(val)" (focus)="removeMask()"
              [readonly]="isReadOnly" [disabled]="isReadOnly" [class.disabled-field]="isReadOnly" />
          </div>
          <div class="col-lg-1 col-2">
            <i class="fa fa-info-circle pr-4 info-hover" ngbPopover="{{ tooltip | translate }}"
              triggers="click:hover:focus" popoverTitle="{{ field.templateOptions.label | translate }}"
              container="body" placement="left"></i>
          </div>
        </div>
        <input class="slider" type="range" min="{{ field.templateOptions.min }}" max="{{ field.templateOptions.max }}"
          step="{{ field.templateOptions.step }}" [(ngModel)]="sliderVal" (input)="sliderMove()" (change)="sliderMove()"
          *ngIf="!isReadOnly" />
      </fieldset>
    </div>

  </ng-container>
  <ng-container *ngSwitchCase="'wizard'">
    template emtpy
  </ng-container>

  <ng-container *ngSwitchDefault>
    <div class="alert alert-warning">
      Invalid template <{{ theme || 'empty string' }}> expected: 'default' | 'wizard' | 'full'
    </div>
  </ng-container>

</ng-container>
