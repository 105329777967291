import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'amountConverter',
})
export class AmountConverterPipe implements PipeTransform {
  transform(value: number): string {
    let v: any = value;
    let val = (v / 1).toFixed(0); // this number represents the numbers after comma
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
    // return new Intl.NumberFormat('de-CH', {
    //     style: 'currency', currency: 'CHF'
    // }).format(Number(value));
  }
}
