import { WizardService } from '../../wizard.service';
import { Component } from '@angular/core';

@Component({
  selector: 'wizard-summary-screen',
  templateUrl: 'wizard-summary.screen.component.html',
})
export class WizardSummaryScreenComponent {
  constructor(public wizardSer: WizardService) {}

  get imageSrc() {
    let src = '/assets/images/wizard-result-negative.svg';
    if (this.wizardSer.model.isGenerallyFinancable)
      src = '/assets/images/wizard-result-positive.svg';
    if (this.wizardSer.model.isGenerallyLimitedFinancable)
      src = '/assets/images/wizard-result-limited.svg';

    return src;
  }

  get title() {
    let src = 'summary.negative.title';
    if (this.wizardSer.model.isGenerallyFinancable)
      src = 'summary.positive.title';
    if (this.wizardSer.model.isGenerallyLimitedFinancable)
      src = 'summary.limited.title';

    return src;
  }

  get faLogo() {
    let src = 'times-circle';
    if (this.wizardSer.model.isGenerallyFinancable) src = 'check-circle';
    if (this.wizardSer.model.isGenerallyLimitedFinancable)
      src = 'exclamation-circle';

    return src;
  }

  get accent() {
    let src = 'danger';
    if (this.wizardSer.model.isGenerallyFinancable) src = 'success';
    if (this.wizardSer.model.isGenerallyLimitedFinancable) src = 'warning';

    return src;
  }

  get leadTitle() {
    let txt = 'summary.negative.title';
    if (this.wizardSer.model.isGenerallyFinancable)
      txt = 'summary.positive.title';
    if (this.wizardSer.model.isGenerallyLimitedFinancable)
      txt = 'summary.limited.title';

    return txt;
  }
  get leadText() {
    let txt = 'summary.negative.line3';
    if (this.wizardSer.model.isGenerallyFinancable)
      txt = 'summary.positive.line3';
    if (this.wizardSer.model.isGenerallyLimitedFinancable)
      txt = 'summary.limited.line3';

    return txt;
  }
}
