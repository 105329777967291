import { CalculatorModule } from './../../modules/calculator/calculator.module';
import { ImportableTranslatorModule } from './../../modules/translator/importableTranslator.module';
import { ImportableFormModule } from './../../modules/form/form.module';
import { TranslateModule } from '@ngx-translate/core';
import { FramePageComponent } from './frame.page.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ImportableFormModule,
    ImportableTranslatorModule.forChild(),
    CalculatorModule,
  ],
  declarations: [FramePageComponent],
  exports: [FramePageComponent],
})
export class FramePageModule {}
