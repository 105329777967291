import {Component, ViewChild, ViewContainerRef} from '@angular/core';
import {FieldWrapper} from '@ngx-formly/core';

@Component({
    selector: 'app-formly-horizontal-wrapper',
    template: `

        <div class="col-lg-6">
            <ng-template #fieldComponent></ng-template>
        </div>

    `,
})
export class FormlyHorizontalWrapperComponent extends FieldWrapper {
    @ViewChild('fieldComponent', {static:true, read: ViewContainerRef}) fieldComponent: ViewContainerRef;
}
