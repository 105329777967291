import { ImportableTranslatorModule } from './../translator/importableTranslator.module';
import { RingLoaderComponent } from './ringLoader.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule, ImportableTranslatorModule],
  declarations: [RingLoaderComponent],
  exports: [RingLoaderComponent],
})
export class RingLoaderModule {}
