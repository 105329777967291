import { ImportableTranslatorModule } from './../translator/importableTranslator.module';
import { IconAlertComponent } from './iconAlert.component';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [ImportableTranslatorModule],
  declarations: [IconAlertComponent],
  exports: [IconAlertComponent],
})
export class IconAlertModule {}
