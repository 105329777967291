import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-icon-alert',
  template: `
    <div class="alert alert-{{ type }}">
      <div class="row d-flex h-100">
        <div class="col-lg-2 offset-1 d-none d-lg-block text-right">
          <span class="fa fa-{{ icon }} fa-{{ sizeMuliplier }}x"></span>
        </div>
        <div class="col-lg-8 justify-content-center align-self-center">
          {{ text | translate }}
        </div>
      </div>
    </div>
  `,
})
export class IconAlertComponent {
  @Input()
  sizeMuliplier: number = 3;

  @Input()
  type: 'danger' | 'success' | 'warning' | 'info' = 'success';

  @Input()
  icon: string = 'info-circle';

  @Input()
  title: string;

  @Input()
  text: string;
}
